import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'

import monthlyPlan from '../images/monthly_plan.jpg'
import deepDivePlan from '../images/deep_dive_plan.jpg'
import reversityUniversity from '../images/reversity_university.jpg'

import {
  Flex,
  Box,
  Text,
  Image,
  Card,
  Button
} from 'rebass'

const PlansPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text textAlign='center' fontWeight='bold' py={2} fontSize={5}>Reversity Programs</Text>
        </Box>
      </Flex>

      <Flex flexWrap='wrap' px={3} py={4}>
        <Box width={[1, 1/3]} p={2}>
          <Card
            bg='white'
            variant='primary'
            fontSize={3}>
            <Image src={monthlyPlan} width={1} alt='Mobile phone and healthy food' />
            <Box py={3}>
              <Text textAlign='center'>Custom Reversity Program</Text>
              <Text fontSize={2} color='gray' textAlign='center'>$199.99/month</Text>
            </Box>
            <Link to='/plans/custom-reversity-plan'>
              <Button variant='fullLength'>Learn more</Button>
            </Link>
          </Card>
        </Box>
        <Box width={[1, 1/3]} p={2}>
          <Card
            bg='white'
            variant='primary'
            fontSize={3}>
            <Image src={deepDivePlan} width={1} alt='Mobile phone and healthy food' />
            <Box py={3}>
              <Text textAlign='center'>Single Deep Dive</Text>
              <Text fontSize={2} color='gray' textAlign='center'>$149.99 one time</Text>
            </Box>
            <Link to='/plans/one-time-deep-dive'>
              <Button variant='fullLength'>Learn more</Button>
            </Link>
          </Card>
        </Box>
        <Box width={[1, 1/3]} p={2}>
          <Card
            variant='primary'
            fontSize={3}>
            <Image src={reversityUniversity} width={1} alt='Mobile phone and healthy food' />
            <Box py={3}>
              <Text textAlign='center'>Reversity University</Text>
              <Text fontSize={2} color='gray' textAlign='center'>$49.99/month</Text>
            </Box>
            <Link to='/plans/reversity-university'>
              <Button variant='fullLength'>Learn more</Button>
            </Link>
          </Card>
        </Box>
      </Flex>
    </Container>
    <BottomHero bg='lightGray' />
  </Layout>
)

export default PlansPage
